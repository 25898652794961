import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/components/shared/login.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthServiceService } from 'src/app/commonSercice/auth-service.service';
import { sample } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  password :any;
  loginForm = this.fb.group({
    userName: ['', [Validators.required, Validators.email]],
    passWord: ['', Validators.required]
  })
  email;
  toastrInstance;
  constructor(private verifier: LoginService,
    private fb: FormBuilder, private router: Router,
    private auth: AuthServiceService, private toastr: ToastrService, private route: ActivatedRoute) {
    // const cryptr = new Cryptr('Eflight_Hash_Password_Key');
    let self = this;
    this.password={};
    this.router.events.subscribe(val => {
      if (val['url']) {
        let hash = val['url'].replace('/reset-password/', '')
        this.verifier.validateHash({ hash_key: hash })
          .subscribe(result => {
            this.email = result['email'];
            console.log(result);
          }, err => {
            console.log(err);
            window.location.href = '';
            // self.router.navigate['/'];
          });
        // console.log(cryptr.decrypt(encryptedString));
      }
    });
  }

  ngOnInit() {

    this.route.queryParams.subscribe((params: Params) => {

    });

  }
  closeToaster() {
    if (this.toastrInstance && this.toastrInstance.ToastId) {
      this.toastr.clear(this.toastrInstance.ToastId);
    }
    if (this.toastrInstance && this.toastrInstance.toastId) {
      this.toastr.clear(this.toastrInstance.toastId);
    }

  }
  saveNewPassword() {

    let passwordRegex = '/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$%*&? "])[a-zA-Z0-9!#$%*&?]{8,20}$/';

    if (this.password && (this.password['newenter'] == this.password['reenter'])) {

      var patt = new RegExp(passwordRegex);
      var res = patt.test(this.password['newenter']);
      console.log(res);
      var p = this.password['newenter'],
        errors = '';
      if (p.length < 8) {
        errors = "Your password must be at least 8 characters";
      }
      if (p.search(/[a-z]/i) < 0) {
        errors = "Your password must contain at least one letter.";
      }
      if (p.search(/[0-9]/) < 0) {
        errors = "Your password must contain at least one digit.";
      }
      if (errors.length > 0) {
        // var errorMsg = 'Password must include at least one upper case letter, one lower case letter, and one numeric digit'
        this.toastrInstance = this.toastr.error(errors.toUpperCase(), 'Alert');
        return;
      }
      var setForEmail = this.email;
      var data = {
        password: this.password['newenter'],
        email: setForEmail
      }
      this.verifier.reSetPassword(data).subscribe(res => {
        localStorage.removeItem('emailforReset')
        this.router.navigate(['/']);
      }, err => {
        console.log('err', err)
      })
    }
    else {
      var errorMsg = 'passwords do not match'
      this.toastrInstance = this.toastr.error(errorMsg.toUpperCase(), 'Alert');
    }

  }
}
