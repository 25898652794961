import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../../components/shared/login.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PaginationInstance } from 'ngx-pagination/dist/ngx-pagination.module';
import { OrderPipe } from 'ngx-order-pipe';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import Swal from 'sweetalert2';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM-YYYY',
    dateA11yLabel: 'DD-MMM-YYYY',
    monthYearA11yLabel: 'MMM-YYYY',
  },
}
@Component({
  selector: 'app-operator-list',
  templateUrl: './operator-list.component.html',
  styleUrls: ['./operator-list.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class OperatorListComponent implements OnInit {
  userForm = {};
  reportList;
  searchForm: any;
  view = 1;
  sortFilter = 'day_count';
  sortFilterExact = 'day_count';
  sortAscOrder = false;
  toastrInstance;
  operatorList = []
  upcoming = [];
  renewal = [];
  cancel = [];
  loader:any;
  public filter: string = '';
  public maxSize: number = 7;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public responsive: boolean = false;
  public config: PaginationInstance = {
    id: 'advanced',
    itemsPerPage: 25,
    currentPage: 1
  };
  order: 'day_count'
  public labels: any = {
    previousLabel: '<',
    nextLabel: '>',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };
  userinfo;
  constructor(private verifier: LoginService, private route: Router, private toastr: ToastrService, private orderPipe: OrderPipe) {
    console.log(this.orderPipe.transform(this.reportList, this.order)); // both this.array and this.order are from above example AppComponent
    this.userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.searchForm = {
      start_date: moment().startOf('month').add(-1,'month').format(),
      end_date: moment().endOf('year').add(1, 'year').format()
    };
    this.loader =false;
  }
  reset = () => {
    this.view = 1;
    this.searchForm = {
      start_date: moment().startOf('month').format(),
      end_date: moment().endOf('year').add(1, 'year').format()
    };
    this.getOperatorDetails();
  }
  toggleView(id) {
    this.view = id;
    if (id == 1) {
      this.operatorList = this.upcoming;
    } else if (id == 2) {
      this.operatorList = this.cancel;
    } else if (id == 3) {
      this.renewal.sort(function (a, b) {
        return a.day_count - b.day_count
      });
      this.operatorList = this.renewal;
    }
  }
  sortRecord(item) {
    if (item) {
      if (this.sortFilter.substring(0, 1) == '-') {
        this.sortFilter = item;
        this.sortFilterExact = item;
        this.sortAscOrder = true;
      } else {
        this.sortFilter = '-' + item;
        this.sortFilterExact = item;
        this.sortAscOrder = false;
      }
    }
    console.log(this.sortFilter);
    console.log(this.sortFilterExact);
    console.log(this.sortAscOrder);
  }
  ngOnInit() {
    this.getOperatorDetails();
  }
  onPageChange(number: number) {
    console.log('change to page', number);
    this.config.currentPage = number;
  }
  getOperatorDetails = () => {
    this.upcoming = [];
    this.renewal = [];
    this.cancel = [];
    this.view = 1;
    this.verifier.aircraftList({})
      .subscribe(res => {
        this.reportList = res;
        if (this.reportList.length == 0) {
          this.upcoming = [];
          this.renewal = [];
          this.cancel = [];
        }
        let callsignArr = this.userinfo.callsign.toLowerCase().split(',');
        if (this.userinfo.privilege_id != 1) {
          this.reportList = this.reportList.filter(v => {
            if (callsignArr.indexOf(v.callsign.toLowerCase()) == -1) {
              return false;
            } else {
              return true;
            }
          })
        }
        this.reportList.map(val => {
          val.day_count = moment(val.subscription_end_date).diff(moment().startOf('day'), 'days');
          val.callsign = val.callsign.toLowerCase();
        });
        for (var i = 0; i < this.reportList.length; i++) {
          if (this.reportList[i].day_count >= 60 && this.reportList[i].is_active != 0) {
            this.renewal.push(this.reportList[i]);
          } else if (this.reportList[i].is_active == 0) {
            this.cancel.push(this.reportList[i]);
          } else if (this.reportList[i].day_count <= 60 && this.reportList[i].is_active != 0) {
            this.upcoming.push(this.reportList[i]);
          }
        }
        this.operatorList = this.upcoming;
      });
  }
  edit = (id) => {
    this.route.navigate(['/profile'], {
      queryParams: {
        value: id,
      }
    });
  }
  addNewOperator = () => {
    this.route.navigate(['/profile'], {
      queryParams: {
        enable: true,
      }
    });
  }

  search = (e, key) => {
    console.log(this.searchForm)
    console.log(e, key)
    this.searchForm[key] = e;
    this.verifier.aircraftList(this.searchForm)
      .subscribe(res => {
        this.reportList = res;
        let callsignArr = this.userinfo.callsign.toLowerCase().split(',');
        if (this.userinfo.privilege_id != 1) {
          this.reportList = this.reportList.filter(v => {
            if (callsignArr.indexOf(v.callsign.toLowerCase()) == -1) {
              return false;
            } else {
              return true;
            }
          })
        }
        this.reportList.map(val => {
          val.day_count = moment(val.subscription_end_date).diff(moment(), 'days');
          val.callsign = val.callsign.toLowerCase();
        });
        this.renewal = [];
        this.upcoming = [];
        this.cancel = [];
        for (var i = 0; i < this.reportList.length; i++) {
          if (this.reportList[i].day_count >= 60 && this.reportList[i].is_active != 0) {
            this.renewal.push(this.reportList[i]);
          } else if (this.reportList[i].is_active == 0) {
            this.cancel.push(this.reportList[i]);
          } else if (this.reportList[i].day_count <= 60 && this.reportList[i].is_active != 0) {
            this.upcoming.push(this.reportList[i]);
          }
        }
        if (this.view == 1) {
          this.operatorList = this.upcoming;
        } else if (this.view == 2) {
          this.operatorList = this.cancel;
        } else if (this.view == 3) {
          this.renewal.sort(function (a, b) {
            return a.day_count - b.day_count
          })
          this.operatorList = this.renewal;
        }
      });
  }
  closeToaster = () => {
    if (this.toastrInstance && this.toastrInstance.ToastId) {
      this.toastr.clear(this.toastrInstance.ToastId);
    }
    if (this.toastrInstance && this.toastrInstance.toastId) {
      this.toastr.clear(this.toastrInstance.toastId);
    }
  }
  deleteOperatorDetails = (id) => {
    let mes = 'Are you sure to delete this OPERATOR';
    Swal.fire({
      title: 'DELETE OPERATOR?',
      text: mes.toUpperCase(),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.verifier.updateOperator({ id: id, is_active: 0 }).subscribe(res => {
          if (res && res[0]) {
            this.toastrInstance = this.toastr.success('operator deleted successfully', 'Alert');
            let index = this.reportList.findIndex(item => item.id === id); //find index in your array
            this.reportList.splice(index, 1);
            let self = this;
            setTimeout(function () {
              self.closeToaster();
              self.search('', 'callsign');

            }, 1500);
          }
        })

      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })

  }
}
