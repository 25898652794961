import { Component, OnInit } from '@angular/core';
import {data}from '../../commonSercice/commonData/data'
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
title
  constructor() {
    this.title=data.footerTitle;
   }

  ngOnInit() {
  }

}
