import { FlyTimeComponent } from './modules/fly-time/fly-time.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { ErrorComponent } from './components/error/error.component';
import { AuthguardGuard } from './commonSercice/authguard.guard';
import { ProfileComponent } from './modules/dashboard/components/profile/profile.component';
import { ResetPasswordComponent } from './modules/dashboard/components/reset-password/reset-password.component';
import { OperatorListComponent } from './modules/dashboard/components/operator-list/operator-list.component';

const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  component: LoginComponent,
},
{
  path: 'login',
  redirectTo: ''
},
{
  path: 'alert-fpl',
  loadChildren: 'src/app/modules/dashboard/dashboard.module#DashboardModule',
  // canActivate:[AuthguardGuard]
},
{
  path: 'profile',
  pathMatch: 'full',
  component: ProfileComponent
  // canActivate:[AuthguardGuard]
},
{
  path: 'reset-password/:hash',
  pathMatch: 'full',
  component: ResetPasswordComponent
  // canActivate:[AuthguardGuard]
},
{
  path: 'fly-time',
  pathMatch: 'full',
  component : FlyTimeComponent
},
{
  path: 'fpl',
  loadChildren: 'src/app/modules/user-dashboard/user-dashboard.module#UserDashboardModule',
},
{
  path:'license',
  loadChildren:'src/app/modules/license/license.module#LicenseModule',
},
{
  path: 'fdtl',
  loadChildren: 'src/app/modules/fdtl/fdtl.module#FdtlModule',
},
{
  path: 'navlog',
  loadChildren: 'src/app/modules/navlog/navlog.module#NavlogModule',
},
{
  path: 'fuelorder',
  loadChildren: 'src/app/modules/fuelorder/fuelorder.module#FuelOrderModule',
},
{
  path: 'notams',
  loadChildren: 'src/app/modules/watch-hours/watch-hours.module#WatchHoursModule',
},
{
  path: 'watch-hours',
  loadChildren: 'src/app/modules/watch-hours/watch-hours.module#WatchHoursModule',
},
{
  path: 'load-trim',
  loadChildren: 'src/app/modules/load-trim/load-trim.module#LoadTrimModule',
},

{
  path: 'aircraft-list',
  pathMatch: 'full',
  component: OperatorListComponent
},
{
  path: '**',
  component: ErrorComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
