import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
    name: 'decimalfilter'
})

export class DecimalFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any {
        return parseFloat(items.toString()).toFixed(2);
    }
}
