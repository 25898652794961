import { Component } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Location } from "@angular/common";
import { ApiCallService } from './commonSercice/api-call.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'efpl-web';
  headerHide: Boolean = false;
  loader = false;
  locationPath;
  constructor(location: Location, router: Router, private axios: ApiCallService) {
    // this.axios.get('http://ip.tripsupport.in/', '')
    //   .subscribe(res => {
    //     localStorage.setItem('user_ip', res['ip']);
    //   })

    router.events.subscribe(val => {
      this.loader = true;
      let _this = this;
      setTimeout(function () {
        _this.loader = false;
      }, 800);
      if (location.path() == '' || location.path() == 'login' || location.path().includes('/reset-password/')) {
        // console.log('hide ', location.path());
        this.headerHide = true;
      } else {
        var userInfo = localStorage.getItem('userinfo');
        if (userInfo) {
          userInfo = JSON.parse(userInfo);
          // if (userInfo && userInfo['privilege_id'] == 6 && (location.path() != 'fdtl/heli-report' || location.path() != 'fdtl/fixedwing-report' || location.path() != 'heli-violation-report' || location.path() != 'fixedwing-violation-report' || location.path() != 'heli-schedule-report' || location.path() != 'fixedwing-schedule-report')) {
          //   router.navigate(['/fdtl/heli-report']);
          // }
          if (userInfo['operator_info']) {
            if (location.path().includes('fpl') && userInfo['operator_info'][0] && userInfo['operator_info'][0].is_fpl != true) {
              window.location.href = '/';
            }
            if (location.path().includes('fdtl') && userInfo['operator_info'][0] && userInfo['operator_info'][0].is_fdtl != true) {
              window.location.href = '/';
            }
            if (location.path().includes('navlog') && userInfo['operator_info'][0] && userInfo['operator_info'][0].is_navlog != true) {
              window.location.href = '/';
            }
             if (location.path().includes('fuelorder') && userInfo['operator_info'][0] && userInfo['operator_info'][0].is_fuelorder != true) {
              window.location.href = '/';
            }
            if (location.path().includes('license') && userInfo['operator_info'][0] && userInfo['operator_info'][0].is_lr != true) {
              window.location.href = '/';
            }
          }
        }
        var userid = localStorage.getItem('userid');
        this.locationPath = location.path();
        this.headerHide = false;
        if (!userid) {
          window.location.href = '/';
        }
      }
      // alert(this.headerHide)
    });
  }
  ngOnInit() {
    // this.route.url.subscribe(url => {console.log("alam",url[0].path);

  }
}
