import { Injectable } from '@angular/core';
import { ApiCallService } from 'src/app/commonSercice/api-call.service';
import { data } from 'src/app/commonSercice/commonData/data';
import * as constants from "src/app/components/shared/config";
import { ApiUrlList } from './config';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private axios: ApiCallService) { }
  logInUser(username, password) {
    return this.axios.post(constants.ApiUrlList.checkUser, {
      "email": username,
      "password": password
    })
  }
  validateHash(obj) {
    return this.axios.post(constants.ApiUrlList.validate_hash, obj);
  }
  testcall(obj) {
    return this.axios.post(constants.ApiUrlList.navlog, obj);
  }
  getIpAddress() {
    return this.axios.get('https://ipinfo.io/json', '');
  }
  forgotPassword(obj) {
    return this.axios.post(constants.ApiUrlList.forgotPassword, obj);
  }
  contactForm(obj) {
    return this.axios.post(constants.ApiUrlList.contactForm, obj);
  }
  addWeekOff(obj) {
    return this.axios.post(constants.ApiUrlList.addWeekOff, obj);
  }
  updateWeekOff(obj) {
    return this.axios.post(constants.ApiUrlList.updateWeekOff, obj);
  }
  listWeekOff(obj) {
    return this.axios.post(constants.ApiUrlList.listweekoff, obj);
  }
  getPilotReport(obj) {
    return this.axios.post(constants.ApiUrlList.getPilotReport, obj);
  }
  getFDTLData(obj) {
    return this.axios.post(constants.ApiUrlList.getFDTLData, obj);
  }
  searchReportFDTL(obj) {
    return this.axios.post(constants.ApiUrlList.search_report_fdtl, obj);
  }
  searchReportFDTLCC(obj) {
    return this.axios.post(constants.ApiUrlList.search_report_fdtl_cabin_crew, obj);
  }
  editFDTL(obj) {
    return this.axios.post(constants.ApiUrlList.edit_fdtl_helicopter, obj);
  }
  saveFDTL(obj) {
    return this.axios.post(constants.ApiUrlList.save_fdtl_helicopter, obj);
  }
  editFDTLFW(obj) {
    return this.axios.post(constants.ApiUrlList.edit_fdtl_fixedwing, obj);
  }
  saveFDTLFW(obj) {
    return this.axios.post(constants.ApiUrlList.save_fdtl_fixedwing, obj);
  }
  helicopterTest(obj) {
    return this.axios.post(constants.ApiUrlList.helicopterTest, obj);
  }
  cabinTest(obj) {
    return this.axios.post(constants.ApiUrlList.cabinTest, obj);
  }
  filePlan(obj) {
    return this.axios.post(constants.ApiUrlList.createJourney, obj);
  }
  getHeliScheduleReport(obj) {
    return this.axios.post(constants.ApiUrlList.get_helischeduleReport, obj);
  }
  getAllPlan(data) {
    return this.axios.post(constants.ApiUrlList.listJourney, data);
  }
  getPlan(data) {
    return this.axios.post(constants.ApiUrlList.journeygetByID, data);
  }
  updatePlan(data) {
    return this.axios.post(constants.ApiUrlList.journeyUpdate + data.id, data);
  }
  updatefuel(data) {
    return this.axios.post(constants.ApiUrlList.fuelUpdate, data);
  }
  fetchPreviousPlanInfo(obj) {
    return this.axios.post(constants.ApiUrlList.fetchPreviousPlanInfo, obj);
  }
  checkCallsignExist(obj) {
    return this.axios.post(constants.ApiUrlList.checkPlanExist, obj);
  }
  dumpfdtlDetails(data) {
    return this.axios.post(constants.ApiUrlList.fdtl_create, data);
  }
  getUniqueCallsign() {
    return this.axios.post(constants.ApiUrlList.uniqueCallsign, {});
  }
  addNewUser(data) {
    return this.axios.post(constants.ApiUrlList.addNewUser, data);
  }
  addNewTempUser(data) {
    return this.axios.post(constants.ApiUrlList.addNewTempUser, data);
  }
  getUserByCallsign(data) {
    return this.axios.post(constants.ApiUrlList.getUserByCallsign, data);
  }
  getAirCraftInfo(data) {
    return this.axios.post(constants.ApiUrlList.getAirCraftInfo, data);
  }
  getCallSignLookupDetails(data){
    return this.axios.post(constants.ApiUrlList.getCallSignLookupDetails,data);
  }
  getCabinCrew(data) {
    return this.axios.post(constants.ApiUrlList.getCabinCrew, data);
  }
  getCabinCrewReport(data) {
    return this.axios.post(constants.ApiUrlList.getCabinCrewReport, data);
  }
  getPilotList(data) {
    if (data.callsign) {
      var uri = '?callsign=' + data.callsign;
    } else {
      var uri = '';
    }
    if (data.is_permanent && uri) {
      uri = uri + '&is_permanent=' + data.is_permanent;
    } else if (data.is_permanent) {
      var uri = '?is_permanent=' + data.is_permanent;
    }
    if (data.type && uri) {
      uri = uri + '&type=' + data.type;
    } else if (data.type) {
      var uri = '?type=' + data.type;
    }
    if (data.page_type && uri) {
      uri = uri + '&page_type=' + data.page_type;
    } else if (data.page_type) {
      var uri = '?page_type=' + data.page_type;
    }
    
    return this.axios.get(constants.ApiUrlList.getPilotList + uri, '');
  }
  updateUser(data) {
    return this.axios.post(constants.ApiUrlList.updateUser, data);
  }
  updateTempUser(data) {
    return this.axios.post(constants.ApiUrlList.updateTempUser, data);
  }
  checkuserByMobile(data) {
    return this.axios.post(constants.ApiUrlList.checkuserByMobile, data);
  }
  checkTempuserByMobile(data) {
    return this.axios.post(constants.ApiUrlList.checkTempuserByMobile, data);
  }
  checkWeekOffValidation(data) {
    return this.axios.post(constants.ApiUrlList.checkWeekOffValidation, data);
  }
  getLastScheduleRecords(data) {
    return this.axios.post(constants.ApiUrlList.getLastScheduleRecords, data);
  }
  fdtl_fixed_wing_test(data) {
    return this.axios.post(constants.ApiUrlList.fixed_wing_test, data);
  }
  add_operator(data) {
    return this.axios.post(constants.ApiUrlList.add_operator, data);
  }
  checkOprCode(data) {
    return this.axios.post(constants.ApiUrlList.checkOprCode, data);
  }
  updateOperator(data) {
    return this.axios.post(constants.ApiUrlList.updateOperator, data);
  }
  addAirportTimings(data) {
    return this.axios.post(constants.ApiUrlList.addAirportTimings, data)
  }
  list(data) {
    return this.axios.post(constants.ApiUrlList.listAirportTimings, data)
  }
  searchOperator(data) {
    return this.axios.post(constants.ApiUrlList.search_operator, data);
  }
  reSetPassword(data) {
    return this.axios.post(constants.ApiUrlList.reSetPassword, data)
  }
  deleteFdtlEntry(data) {
    return this.axios.post(constants.ApiUrlList.delete_Fdtl_Entry, data)
  }
  searchHistoryReport(data) {
    return this.axios.post(constants.ApiUrlList.searchHistoryReport, data)
  }
  aircraftList(data) {
    return this.axios.post(constants.ApiUrlList.aircraftList, data)
  }
  aeroDromlist(data) {
    return this.axios.post(constants.ApiUrlList.aeroDromlist, data)
  }
  watch_hours_by_id (data){
    return this.axios.post(constants.ApiUrlList.watch_hours_by_id, data)
  }
  findOperatorDetail(data) {
    return this.axios.post(constants.ApiUrlList.findOperatorDetail, data)
  }
  updateFplId(data) {
    return this.axios.post(constants.ApiUrlList.updateFplId, data)
  }
  addLicenseReminder(data) {
    return this.axios.post(constants.ApiUrlList.create_licence_reminder, data);
  }
  fetchAllLicenseReminder() {
    return this.axios.get(constants.ApiUrlList.fetchAll_licence_reminder, '');
  }
  updateLicense(data) {
    return this.axios.post(constants.ApiUrlList.update_licence_reminder, data);
  }
  remove(data) {
    return this.axios.post(constants.ApiUrlList.remove, data);
  }
  licenseSearch(data) {
    return this.axios.post(constants.ApiUrlList.licenseSearch, data);
  }
  getNotams(data) {
    return this.axios.post(constants.ApiUrlList.getNotams, data);
  }
  add_watch_hours(data) {
    return this.axios.post(constants.ApiUrlList.add_watch_hours, data);
  }
  list_watch_hours(data) {
    return this.axios.post(constants.ApiUrlList.list_watch_hours, data);
  }
  watch_hours_delete(data) {
    return this.axios.post(constants.ApiUrlList.watch_hours_delete, data);
  }
  
}
