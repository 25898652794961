import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
    name: 'timefilter'
})

export class TimeFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any {
        return moment(items,'HHmm').format(searchText);
    }
}
