import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders,
  HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import "rxjs/add/operator/do";



@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // HttpHeader object immutable - copy values
    // console.log(request);
    if(request.url == 'http://localhost:3001/upload/pdf'){
      // const headerSettings: { [name: string]: string | string[]; } = {};
      // for (const key of request.headers.keys()) {
      //   headerSettings[key] = request.headers.getAll(key);
      // }
      // console.log('==> here in interceptor')
      // if (localStorage.getItem('token')) {
      //   headerSettings['Authorization'] = localStorage.getItem('token');
      // }
      // // headerSettings['Content-Type'] = 'multipart/form-data';
      // const newHeader = new HttpHeaders(headerSettings);
      // request = request.clone({
      //   headers: newHeader
      // });
      return next.handle(request);

    }else{
        
      const headerSettings: { [name: string]: string | string[]; } = {};
      for (const key of request.headers.keys()) {
        headerSettings[key] = request.headers.getAll(key);
      }

      if (localStorage.getItem('token')) {
        headerSettings['Authorization'] = localStorage.getItem('token');
      }
      headerSettings['Content-Type'] = 'application/json';
      const newHeader = new HttpHeaders(headerSettings);
      request = request.clone({
        headers: newHeader
      });
      // return next.handle(request);
      return next.handle(request).do(
        (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // console.log('event',event)
                return event;
            }
        },
        (err) => {
            if (err instanceof HttpErrorResponse) {
                // alert(err.status);
                if(err.status==401){
                  localStorage.clear();
                  window.location.reload();
                }
                return false
            }
        });
    // }

    }
    // return next.handle(request).do(
    //   (event: HttpEvent<any>) => {
    //       if (event instanceof HttpResponse) {
    //           return event;
    //       }
    //   },
    //   (err) => {
    //       if (err instanceof HttpErrorResponse) {
    //           alert(err.status);
    //           return false
    //       }
    //   });
  }
}