import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  inputs: ['pageName']
})
export class SidebarComponent implements OnInit {
  flightPlan
  navLog
  fdtl
  notams
  weather
  airports
  fdtl_subpage_index;
  fdtl_subpage_index1;
  prev_sub_index = -1;
  tripsupport;
  ltrim;
  navlog;
  locationPath;
  pages;
  userInfo;
  enableFWFDTL = false;
  enableHeliFDTL = false;
  constructor(private route: Router, location: Location, private toastr: ToastrService) {
    this.pages = {
      flightPlan: true, navLog: true,
      fdtl: true,
      notams: true,
      weather: true,
      airports: true,
      tripsupport: true,
      ltrim: true,
      navlog: true,
    }
    route.events.subscribe(val => {
      this.userInfo = JSON.parse(localStorage.getItem('userinfo'));

      if (location.path() == '' || location.path() == 'login' || location.path().includes('/reset-password/')) {
        // this.headerHide = true;
      } else {
        var userid = localStorage.getItem('userid');
        this.locationPath = location.path();
        // this.headerHide = false;
        // if (!userid) {
        // window.location.href = '/';
        // }
      }
    });
  }

  ngOnInit() {
    this.flightPlan = true;
    this.navLog = true;
    this.fdtl = true;
    this.notams = true;
    this.weather = true;
    this.airports = true;
    this.navlog = true;
    this.userInfo = JSON.parse(localStorage.getItem('userinfo'));
    if (this.userInfo) {
      if (this.userInfo.privilege_id == 1) {
        this.enableHeliFDTL = true;
        this.enableFWFDTL = true;
      }
      for (var i = 0; i < this.userInfo.operator_info.length; i++) {
        if (this.userInfo.operator_info[i].flight_category == "helicopter") {
          this.enableHeliFDTL = true;
        }
        if (this.userInfo.operator_info[i].flight_category != "helicopter") {
          this.enableFWFDTL = true;
        }
      }
    }

  }
  openMainLevel(key) {
    let pages = {
      flightPlan: true,
      navLog: true,
      fdtl: true,
      notams: true,
      weather: true,
      airports: true,
      tripsupport: true,
      ltrim: true,
      navlog: true,
    }
    for (let keys in pages) {
      if (keys == key) {
        this.pages[key] = !this.pages[key]
      } else {
        this.pages[keys] = true
      }
    }
  };
  openSublevelFDTl (i) {
    if (this.prev_sub_index == i) {
      this.prev_sub_index = -1;
      this.fdtl_subpage_index1 = -1;
    } else {
      this.prev_sub_index = i;
      this.fdtl_subpage_index1 = i;
    }

  }
  openSublevel(i) {
    if (this.prev_sub_index == i) {
      this.prev_sub_index = -1;
      this.fdtl_subpage_index = -1;
    } else {
      this.prev_sub_index = i;
      this.fdtl_subpage_index = i;
    }

  }
  addFpl() {
    if (this.userInfo.privilege_id == 6) {
      this.toastr.error('YOUR ACCOUNT IS NOT YET SUBSCRIBED TO THIS PAGE, CONTACT OUR ACCOUNTS TEAM TO ACTIVATE');
      return;
    }
    this.route.navigate(['fpl/add']);

  }
  fplList() {
    if (this.userInfo.privilege_id == 6) {
      this.toastr.error('YOUR ACCOUNT IS NOT YET SUBSCRIBED TO THIS PAGE, CONTACT OUR ACCOUNTS TEAM TO ACTIVATE');
      return;
    }
    this.route.navigate(['fpl']);
  }
  QuickFPl() {
    if (this.userInfo.privilege_id == 6) {
      this.toastr.error('YOUR ACCOUNT IS NOT YET SUBSCRIBED TO THIS PAGE, CONTACT OUR ACCOUNTS TEAM TO ACTIVATE');
      return;
    }
    this.route.navigate(['fpl/quickFpl']);
  }
  addFdtl() {
    if (this.userInfo.privilege_id == 6) {
      this.toastr.error('YOUR ACCOUNT IS NOT YET SUBSCRIBED TO THIS PAGE, CONTACT OUR ACCOUNTS TEAM TO ACTIVATE');
      return;
    }
    this.route.navigate(['fdtl/add']);
  }
  listFdtl() {
    if (this.userInfo.privilege_id == 6) {
      this.toastr.error('YOUR ACCOUNT IS NOT YET SUBSCRIBED TO THIS PAGE, CONTACT OUR ACCOUNTS TEAM TO ACTIVATE');
      return;
    }
    this.route.navigate(['fdtl']);
  }
  comingsoon = () => {
    this.toastr.success('COMING SOON.....');
  }
  loadPage(url) {
    this.userInfo = JSON.parse(localStorage.getItem('userinfo'));
    if (this.userInfo) {
      if (this.userInfo.privilege_id == 1) {
        this.enableHeliFDTL = true;
        this.enableFWFDTL = true;
      }
      for (var i = 0; i < this.userInfo.operator_info.length; i++) {
        if (this.userInfo.operator_info[i].flight_category == "helicopter") {
          this.enableHeliFDTL = true;
        }
        if (this.userInfo.operator_info[i].flight_category != "helicopter") {
          this.enableFWFDTL = true;
        }
      }
    }
    console.log(url);
    console.log(!url.includes('history'));
    console.log(!url.includes('report'));
    if (this.userInfo.privilege_id == 6 && !url.includes('report') && !url.includes('history')) {
      this.toastr.error('YOUR ACCOUNT IS NOT YET SUBSCRIBED TO THIS PAGE, CONTACT OUR ACCOUNTS TEAM TO ACTIVATE');
      return;
    }
    let accessGranted = false;

    if (this.userInfo.operator_info && this.userInfo.operator_info[0]) {
      if (url.includes('fpl') && this.userInfo.operator_info[0].is_fpl) {
        accessGranted = true;
      }
      if (url.includes('fdtl') && this.userInfo.operator_info[0].is_fdtl) {
        if (this.enableHeliFDTL && (url.includes('heli') || url.includes('test') && !url.includes('test1'))) {
          accessGranted = true;
        }
        console.log('his.accessGranted',this.enableFWFDTL)
        if (this.enableFWFDTL && (url.includes('fixedwing') || url.includes('fixed-wing') || url.includes('test1'))) {
          console.log('his.accessGranted')
          accessGranted = true;
        }
      }
      if (url.includes('navlog') && this.userInfo.operator_info[0].is_navlog) {
        accessGranted = true;
      }
      if (url.includes('license') && this.userInfo.operator_info[0].is_lr) {
        accessGranted = true;
      }
      if (accessGranted) {
        this.route.navigate([url]);
      } else {
        this.toastr.error('1YOUR ACCOUNT IS NOT YET SUBSCRIBED TO THIS PAGE, CONTACT OUR ACCOUNTS TEAM TO ACTIVATE');
      }
    } else {
      this.route.navigate([url]);
    }
  }
}
