import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
@Injectable()
export class UserAcessService implements CanActivate {
    constructor(public router: Router) { }
    canActivate(): boolean {
        var userinfo = JSON.parse(localStorage.getItem('userinfo'));
        if (userinfo.privilege_id == 1) {
            return true;
        }
        this.router.navigate(['/fpl']);
        return false;
    }
}
