import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule ,HTTP_INTERCEPTORS} from "@angular/common/http";
import { ClickOutsideDirective } from './clickOutside.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { SharedModule } from './commonSercice/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { ErrorComponent } from './components/error/error.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { UserAcessService } from './commonSercice/auth/user-access';
import { ProfileComponent } from './modules/dashboard/components/profile/profile.component';
import { ResetPasswordComponent } from './modules/dashboard/components/reset-password/reset-password.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatInputModule,MatAutocompleteModule } from '@angular/material';
import { OperatorListComponent } from './modules/dashboard/components/operator-list/operator-list.component';
import { OrderPipe } from 'ngx-order-pipe';
import { TokenInterceptor } from './commonSercice/interceptor/interceptor';
import { FlyTimeComponent } from './modules/fly-time/fly-time.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ErrorComponent,
    SidebarComponent,
    ProfileComponent,
    ResetPasswordComponent,
    OperatorListComponent,
    FlyTimeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    SharedModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      // closeButton: true,
      // disableTimeOut: true
    })
  ],
  providers: [UserAcessService, DatePipe, OrderPipe,    {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
