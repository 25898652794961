import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
    name: 'filter'
})

export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any {
        if (searchText == 'IST') {
            return moment(items).add(330,'minutes').format('DD-MMM-YYYY');
        } else {
            return moment(items, 'YYYYMMDD').format(searchText);
        }
    }
}
