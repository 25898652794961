import { Component, OnInit } from '@angular/core';
import { LoginService } from '../shared/login.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthServiceService } from 'src/app/commonSercice/auth-service.service';
import { data } from '../../commonSercice/commonData/data'
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../../../assets/css/style-home.css', '../../../assets/css/fonts.css']
})
export class LoginComponent implements OnInit {
  footertitle: any
  loginForm = this.fb.group({
    userName: ['', [Validators.required, Validators.email]],
    passWord: ['', Validators.required]
  })
  loginModel = {
    username: '',
    password: ''
  };
  toastrI;
  showFgtPass: any;
  loginflag: any;
  contact: any;
  forgotInput: number;
  loader: boolean;
  constructor(
    private verifier: LoginService,
    private fb: FormBuilder,
    private route: Router,
    private auth: AuthServiceService,
    private toastr: ToastrService
  ) {
    this.footertitle = data.footerTitle;
    this.showFgtPass = false;
    let _this = this;
    //  '../../../assets/css/fonts.css',
    // setTimeout(function () {
    _this.loadScript('assets/js/core.min.js');
    _this.loadScript('assets/js/script.js');
    // }, 1000);
    this.contact = {
      name: null,
      email: null,
      phone: null,
      message: null
    };
    this.forgotInput = null;
  }

  ngOnInit() {


  }
  loadScript(url) {
    console.log('preparing to load...')
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  closeLogin(e) {
    // console.log(e);
    // console.log(e.toElement);
    // console.log(e.toElement.className);
    if (e.toElement.className != 'hidden_btn') {
      this.loginflag = false;
    }
  }
  toggleLogin() {
    this.loginflag = !this.loginflag;
  }
  forgotFunction() {
    this.showFgtPass = true;
  }
  backTologFunction() {
    this.showFgtPass = false;
  }
  clearToastr() {
    if (this.toastrI && this.toastrI.ToastId) {
      this.toastr.clear(this.toastrI.ToastId);
    }
    if (this.toastrI && this.toastrI.toastId) {
      this.toastr.clear(this.toastrI.toastId);
    }
  }
  login(value1) {
    // console.log("Value", value1)
    let val;
    this.clearToastr();
    if (this.loginModel.username == '' || this.loginModel.password == '') {
      this.toastrI = this.toastr.error('Invalid Username or password', 'Alert')
      return;
    }
    this.loader = true;

    this.verifier.logInUser(this.loginModel.username, this.loginModel.password)
      .subscribe(res => {
        console.log(res);
        console.log(res['active']);
        localStorage.setItem('token', res['token']);

        if (res['active'] == 0) {
          let message = 'YOUR SUBSCRIPTION TO OUR SERVICE HAS EXPIRED, PLEASE CONTACT OUR ACCOUNTS TEAM TO RENEW.';
          this.toastrI = this.toastr.error(message, 'Alert')
          return;
        }

        if (res['is_password_change'] == 0) {
          localStorage.setItem('emailforReset', res['email']);
          // this.toastrI = this.toastr.error('Un Authorised', 'Alert')
          this.loader = false;
          let url = 'reset-password/' + res['hash_key'];
          // console.log(url);
          this.route.navigate([url]);
          // return;
        } else {

          localStorage.removeItem('lastSearchObj');

          if (res && res['userId']) {
            this.checkActiveStatus(res);


          } else if (res && res[0] && res[0]['userId']) {
            this.checkActiveStatus(res[0]);
            localStorage.setItem('userid', res[0]['userId']);
            localStorage.setItem('userinfo', JSON.stringify(res));

            // this.route.navigate(['fpl']);
            this.loader = false;

          }
          else {
            this.loader = false;

            console.log('failed');
            this.toastrI = this.toastr.error('Invalid Username or password', 'Alert')
            // Swal.fire({
            //   title: 'FAILED',
            //   text: 'Invalid Username or password',
            //   type: 'error',
            // showCancelButton: true,
            // confirmButtonText: 'ok',
            // cancelButtonText: 'No, keep it'
          }
        }

      })
    // .then((result) => {
    // if (result.value) {
    //   Swal.fire(
    //     'Deleted!',
    //     'Your imaginary file has been deleted.',
    //     'success'
    //   )
    //   // For more information about handling dismissals please visit
    //   // https://sweetalert2.github.io/#handling-dismissals
    // } else if (result.dismiss === Swal.DismissReason.cancel) {
    //   Swal.fire(
    //     'Cancelled',
    //     'Your imaginary file is safe :)',
    //     'error'
    //   )
    // }
    // })
    // }
    // if (value1.value.userName == "alamfareed.fareed@gmail.com") {
    //   this.route.navigate(['admin-dashboard']);
    // }
    // else {
    //   this.route.navigate(['fpl']);
    // }
    // })
    // !val?this.auth.login()&&this.route.navigate(['admin-dashboard']):console.log("No data"); 
    // this.auth.login();
  }
  scroll(element) {
    var ele = document.getElementById(element);
    window.scrollTo(ele.offsetLeft, ele.offsetTop - 500);
    setTimeout(function () {
      window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }, 200)
  }

  checkActiveStatus = (res) => {
    if (res['privilege_id'] != 1) {
      if (!res['callsign']) {
        let message = 'YOUR SUBSCRIPTION TO OUR SERVICE HAS EXPIRED, PLEASE CONTACT OUR ACCOUNTS TEAM TO RENEW.';
        this.toastrI = this.toastr.error(message, 'Alert')
        return;
      }
      let callsign = res['callsign'].split(',')[0];
      this.verifier.getAirCraftInfo({ callsign: callsign })
        .subscribe(val => {
          // console.log(val);
          if (val && val[0]) {
            let diff = moment(val[0].subscription_end_date).diff(moment(), 'minutes');
            if (val[0].is_active == false) {
              localStorage.removeItem('userid');
              localStorage.removeItem('userinfo');
              let message = 'YOUR SUBSCRIPTION TO OUR SERVICE HAS EXPIRED, PLEASE CONTACT OUR ACCOUNTS TEAM TO RENEW.';
              this.toastrI = this.toastr.error(message, 'Alert')
              this.loader = false;
              return;
            }
            // console.log('diff', diff);
            if (diff < 0) {
              // localStorage.removeItem('userid');
              // localStorage.removeItem('userinfo');
              // let message = 'YOUR SUBSCRIPTION TO OUR SERVICE HAS EXPIRED, PLEASE CONTACT OUR ACCOUNTS TEAM TO RENEW.';
              // this.toastrI = this.toastr.error(message, 'Alert')
              // this.loader = false;
              // return;
            }
            localStorage.setItem('userid', res['userId']);
            localStorage.setItem('userinfo', JSON.stringify(res));
            if (res['privilege_id'] == 6) {
              if (res['operator_info'] && res['operator_info'][0] && res['operator_info'][0].flight_category == 'helicopter') {
                this.route.navigate(['/fdtl/heli-report']);
              } else {
                this.route.navigate(['/fdtl/fixedwing-report']);
              }
            } else {
              this.route.navigate(['fpl']);
            }
          }
        });
    } else {
      localStorage.setItem('userid', res['userId']);
      localStorage.setItem('userinfo', JSON.stringify(res));
      if (res['privilege_id'] == 6) {
        this.route.navigate(['/fdtl/heli-report']);
      } else {
        this.route.navigate(['fpl']);
      }
    }
  }
  allowDigits = (event) => {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return (k >= 48 && k <= 57);
  }

  submitContact = (data) => {
    let valid = this.formValidate(data);
    if (valid == true) {
      document.getElementById('contact-email-2').focus();
      document.getElementById('contact-message-2').focus();
      document.getElementById('contact-phone-2').focus();
      document.getElementById('contact-your-name-2').focus();
      document.getElementById('submitContact').focus();

      // Swal.fire(
      //   'Attention!',
      //   'Fill All Mandatory Fields.',
      //   'info'
      // );
      return;
    } else {
      var _this = this
      this.loader = true;
      this.verifier.contactForm(data)
        .subscribe(res => {
          setTimeout(function () {
            _this.loader = false;
            if (res && res && res[`errFlag`]) {
              // Swal.fire(
              //   'Alert',
              //   res[`message`],
              //   'error'
              // );
              _this.toastrI = _this.toastr.error(res[`message`], 'Alert')
              return;
            } else {
              let result = JSON.parse(JSON.stringify(res));
              if (result == 1) {
                // Swal.fire(
                //   'Success!',
                //   'Contact form send.',
                //   'success'
                // );
                console.log('result ', result);
                _this.toastrI = _this.toastr.success('YOUR MESSAGE HAS BEEN SUBMITTED SUCCESSFULLY AND WILL BE RESPONDED AT THE EARLIEST');
                _this.contact = {
                  name: null,
                  email: null,
                  phone: null,
                  message: null
                };
              }
            }
            setTimeout(function () {
              _this.clearToastr();
            }, 3000);
          }, 2000);
        });
    }
  }

  formValidate = (data) => {
    let valid = false;
    if (data.name == null) {
      valid = true;
    }
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (data.email == null || reg.test(data.email) == false) {
      valid = true;
    }
    if (data.phone == null) {
      valid = true;
    }
    if (data.message == null) {
      valid = true;
    }
    return valid;
  }

  submitForgot = () => {
    const _this = this;
    this.verifier.forgotPassword({ forgotInput: this.forgotInput })
      .subscribe(res => {
        let result = JSON.parse(JSON.stringify(res));
        localStorage.setItem('emailforReset', result.responseData.email);
        if (result && result.responseStatus == 'responseError') {
          _this.toastrI = _this.toastr.error(result.responseData, 'Alert');
        } else {
          Swal.fire(
            'Success!',
            'Reset link send to your mail.',
            'success'
          );
          this.forgotInput = null;
          this.showFgtPass = false;
        }
      });
  }

}
