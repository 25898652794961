import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/components/shared/login.service';

import * as moment from 'moment/moment';

@Component({
  selector: 'app-fly-time',
  templateUrl: './fly-time.component.html',
  styleUrls: ['./fly-time.component.css']
})

export class FlyTimeComponent implements OnInit {

  aeroDromlist= [
    {
      "airport_name": "HYDERABAD (BEGUMPET)",
      "icao_code": "VOHY",
      "lat": 1727,
      "long": 7828
    },
    {
      "airport_name": "HYDERABAD (SHAMSHABAD)",
      "icao_code": "VOHS",
      "lat": 1714,
      "long": 7826
    },
    {
      "airport_name": "VIJAYAWADA",
      "icao_code": "VOBZ",
      "lat": 1632,
      "long": 8048
    },
    {
      "airport_name": "RAJAHMUNDRY",
      "icao_code": "VORY",
      "lat": 1707,
      "long": 8149
    },
    {
      "airport_name": "TIRUPATI",
      "icao_code": "VOTP",
      "lat": 1338,
      "long": 7933
    },
    {
      "airport_name": "PUTTAPARTHI",
      "icao_code": "VOPN",
      "lat": 1409,
      "long": 7747
    },
    {
      "airport_name": "CHENNAI",
      "icao_code": "VOMM",
      "lat": 1300,
      "long": 8010
    },
    {
      "airport_name": "TIRUCHIRAPPALLI",
      "icao_code": "VOTR",
      "lat": 1046,
      "long": 7843
    },
    {
      "airport_name": "MADURAI",
      "icao_code": "VOMD",
      "lat": "09 50",
      "long": 7805
    },
    {
      "airport_name": "COIMBATORE",
      "icao_code": "VOCB",
      "lat": 1102,
      "long": 7703
    },
    {
      "airport_name": "TUTICORIN",
      "icao_code": "VOTK",
      "lat": "08 43",
      "long": 7802
    },
    {
      "airport_name": "HOSUR",
      "icao_code": "VO95",
      "lat": 1239,
      "long": 7746
    },
    {
      "airport_name": "SALEM",
      "icao_code": "VOSM",
      "lat": 1147,
      "long": 7804
    },
    {
      "airport_name": "THANJAVUR",
      "icao_code": "VOTJ",
      "lat": 1043,
      "long": 7906
    },
    {
      "airport_name": "PUDUCHERRY",
      "icao_code": "VOPC",
      "lat": 1158,
      "long": 7949
    },
    {
      "airport_name": "BANGALORE HAL",
      "icao_code": "VOBG",
      "lat": 1257,
      "long": 7740
    },
    {
      "airport_name": "BANGALORE INTERNATIONAL",
      "icao_code": "VOBL",
      "lat": 1312,
      "long": 7742
    },
    {
      "airport_name": "HUBLI",
      "icao_code": "VOHB",
      "lat": 1522,
      "long": 7505
    },
    {
      "airport_name": "BELGAUM",
      "icao_code": "VOBM",
      "lat": 1552,
      "long": 7437
    },
    {
      "airport_name": "MANGALORE",
      "icao_code": "VOML",
      "lat": 1258,
      "long": 7453
    },
    {
      "airport_name": "MYSORE",
      "icao_code": "VOMY",
      "lat": 1214,
      "long": 7639
    },
    {
      "airport_name": "JINDAL VIDYANAGAR",
      "icao_code": "VOJV",
      "lat": 1511,
      "long": 7638
    },
    {
      "airport_name": "BALDOTA KOPPAL",
      "icao_code": "VOBK",
      "lat": 1521,
      "long": 7613
    },
    {
      "airport_name": "GULBARGA",
      "icao_code": "VOGB",
      "lat": 1719,
      "long": 7657
    },
    {
      "airport_name": "GOA",
      "icao_code": "VOGO",
      "lat": 1523,
      "long": 7350
    },
    {
      "airport_name": "CALICUT",
      "icao_code": "VOCL",
      "lat": 1108,
      "long": 7557
    },
    {
      "airport_name": "COCHIN",
      "icao_code": "VOCI",
      "lat": 1009,
      "long": 7624
    },
    {
      "airport_name": "THIRUVANANTHAPURAM",
      "icao_code": "VOTV",
      "lat": "08 29",
      "long": 7655
    },
    {
      "airport_name": "KANNUR",
      "icao_code": "VOKN",
      "lat": 1155,
      "long": 7533
    },
    {
      "airport_name": "PUNE",
      "icao_code": "VAPO",
      "lat": 1835,
      "long": 7355
    },
    {
      "airport_name": "MUMBAI",
      "icao_code": "VABB",
      "lat": 1905,
      "long": 7252
    },
    {
      "airport_name": "OZAR",
      "icao_code": "VAOZ",
      "lat": 2007,
      "long": 7355
    },
    {
      "airport_name": "AURANGABAD",
      "icao_code": "VAAU",
      "lat": 1952,
      "long": 7524
    },
    {
      "airport_name": "NAGPUR",
      "icao_code": "VANP",
      "lat": 2105,
      "long": 7903
    },
    {
      "airport_name": "SHIRDI",
      "icao_code": "VASD",
      "lat": 1941,
      "long": 7422
    },
    {
      "airport_name": "NEW DELHI",
      "icao_code": "VIDP",
      "lat": 2834,
      "long": 7707
    },
    {
      "airport_name": "JAIPUR",
      "icao_code": "VIJP",
      "lat": 2649,
      "long": 7548
    },
    {
      "airport_name": "JODHPUR",
      "icao_code": "VIJO",
      "lat": 2615,
      "long": 7303
    },
    {
      "airport_name": "UDAIPUR",
      "icao_code": "VAUD",
      "lat": 2437,
      "long": 7354
    },
    {
      "airport_name": "AHMEDABAD",
      "icao_code": "VAAH",
      "lat": 2304,
      "long": 7237
    },
    {
      "airport_name": "BHUBANESHWAR",
      "icao_code": "VEBS",
      "lat": 2015,
      "long": 8549
    },
    {
      "airport_name": "RAIPUR",
      "icao_code": "VERP",
      "lat": 2111,
      "long": 8144
    },
    {
      "airport_name": "BHOPAL",
      "icao_code": "VABP",
      "lat": 2317,
      "long": 7720
    },
    {
      "airport_name": "DEHRADUN",
      "icao_code": "VIDN",
      "lat": 3011,
      "long": 7811
    }
  ];
  callsignlist= [{
    name: 'B200 - VTJMG',
    speed: 240
  }, {
    name: 'CJ2+ - VTBIP',
    speed: 340
  }, {
    name: 'PREMIER 1A - VTVRL',
    speed: 370
  }, {
    name: 'HAWKER 800xp - VTVPA',
    speed: 390
  }, {
    name: 'LEGACY - VTLTD',
    speed: 420
  }];
  constructor(
    private verifier: LoginService,
  ) { }


  flytimeForm = {
    fromlat: 0,
    fromlng: 0,
    tolat: 0,
    tolng: 0,
  };
  filteredOptions1: any;
  speed: any;
  showResult: any;
  time: any;
  distanceN: any;

  ngOnInit() {
    this.showResult = false;
    console.log(this.aeroDromlist);
    // this.verifier.aeroDromlist({})
    //   .subscribe(res => {
    //     // this.aeroDromlist = res;
    //     console.log(res);
    //     this.aeroDromlist.map((r) => {
    //       if (r.lat_long && r.icao_code != 'ZZZZ') {
    //         // console.log(r.lat_long, r.icao_code);
    //       }
    //     })
    //   });
  }
  speedSelect(speed){
    console.log(speed);
    console.log(this.speed);
  }
  selectedOption(e, type) {
    console.log(e, type);
    this.aeroDromlist.map(a => {
      if (a.icao_code == e) {
        console.log(a.lat);
        console.log(a);
        console.log(a.long);
        // console.log(a.lat_long.split('N')[0]);
        var ldd = a.lat.toString().substring(0, 2);
        var lmm = a.lat.toString().replace(ldd, '');

        var lodd = a.long.toString().substring(0, 2);
        var lomm = a.long.toString().replace(lodd, '');

        console.log(ldd, lmm, lodd, lomm);
        console.log(parseInt(ldd), (parseInt(lmm) / 60));
        console.log(parseInt(lodd) + (parseInt(lomm) / 60));
        if (type == 'from') {
          this.flytimeForm.fromlat = parseInt(ldd) + (parseInt(lmm) / 60);
          this.flytimeForm.fromlng = parseInt(lodd) + (parseInt(lomm) / 60);
        } else {
          this.flytimeForm.tolat = parseInt(ldd) + (parseInt(lmm) / 60);
          this.flytimeForm.tolng = parseInt(lodd) + (parseInt(lomm) / 60);
        }

      }
    })
  }
  calc = () => {
    this.showResult = true;

    var d = this.distance(this.flytimeForm.fromlat, this.flytimeForm.fromlng, this.flytimeForm.tolat, this.flytimeForm.tolng, 'K');
    var d1 = this.distance(this.flytimeForm.fromlat, this.flytimeForm.fromlng, this.flytimeForm.tolat, this.flytimeForm.tolng, 'N');
    // console.log(d);
    // console.log(d1);
    console.log(this.speed);
    console.log((d1 / this.speed)*60*60);
    var t = (d1 / this.speed)*60*60;
    // console.log(moment().startOf('day').add(t+15,'seconds').format('HH:mm'));
    var temptime =  moment().startOf('day').add(t,'seconds').add(20,'minutes')
    const remainder = 5 - (temptime.minute() % 5);
    this.time = temptime.add(remainder,'minutes').format('HH:mm');
    this.distanceN = Math.floor(d1);
  }
  reset = ()=>{
    this.showResult = false;
    this.distanceN = null;

  }
  distance(lat1, lon1, lat2, lon2, unit) {
    console.log(lat1, lon1, lat2, lon2, unit);
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
                            
      if (unit == "K") { dist = dist * 1.609344 }
      if (unit == "N") { dist = dist * 0.8684 }
      return dist;
    }
  }
  onFieldChange() {

  }
  private _filter1(value: string): any[] {
    const filterValue = value.toLowerCase();
    console.log(this.aeroDromlist);
    return this.aeroDromlist.filter(option => {
      if (option.icao_code.toString().toLowerCase().includes(filterValue))
        return option;
    });
  }
  searchAero(event) {
    // console.log(event);
    if (event && event.length >= 4) {
      this.filteredOptions1 = this._filter1(event);
    }
  }
  allow_digits(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return (k >= 48 && k <= 57);
  }
  allow_alphabets(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8)
  }
  omit_special_char_allow_space(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
  }
  omit_special_char_allow_digits(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 47 && k <= 57));
  }

}
