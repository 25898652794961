import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Loader } from 'src/app/modules/loader/loader';
import { ClickOutsideDirective, ClickOutsideDirective2 } from 'src/app/clickOutside.directive';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatMenuModule } from '@angular/material';
import { FilterPipe } from 'src/app/components/shared/filter.pipe';
import { TimeFilterPipe } from 'src/app/components/shared/timefilter.pipe';
import { DecimalFilterPipe } from 'src/app/components/shared/decimalFilter.pipe';



@NgModule({
    imports: [
        OrderModule,
        NgxPaginationModule,
        MatMenuModule,


    ],
    declarations: [
        Loader,
        ClickOutsideDirective,
        ClickOutsideDirective2,
        FilterPipe,
        TimeFilterPipe,
        DecimalFilterPipe

    ],
    exports: [
        Loader,
        ClickOutsideDirective,
        ClickOutsideDirective2,
        OrderModule,
        NgxPaginationModule,
        MatMenuModule,
        FilterPipe,
        TimeFilterPipe,
        DecimalFilterPipe

    ]
})

export class SharedModule { }