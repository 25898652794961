import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/components/shared/login.service';
import * as moment from 'moment/moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { environment } from "src/environments/environment";
import { Router, ActivatedRoute } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';
var apiUrl = environment.apiUrl;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM-YYYY',
    dateA11yLabel: 'DD-MMM-YYYY',
    monthYearA11yLabel: 'MMM-YYYY',
  },
};

@Component({
  selector: 'app-fdtl-history',
  templateUrl: './fdtl-history.component.html',
  styleUrls: ['./fdtl-history.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class FdtlHistoryComponent implements OnInit {
  searchObj: any;
  reportList;
  pageTitle;
  page_type;
  reportType;
  loader;
  public maxSize: number = 5;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public responsive: boolean = false;
  public config: PaginationInstance = {
    id: 'advanced',
    itemsPerPage: 20,
    currentPage: 1
  };
  order: 'day_count'
  public labels: any = {
    previousLabel: '<',
    nextLabel: '>',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };
  aircraftList;

  constructor(private verifier: LoginService, private router: Router,
    private route: ActivatedRoute, private toastr: ToastrService) {
    this.searchObj = {
      callsign: '',
      pilot: '',
      start_date: moment().startOf('month').add(-1, 'month').toDate(),
      end_date: moment().endOf('month').toDate(),
      operator: ''
    };
    this.router.events.subscribe(val => {
      if (val[`url`]) {
        let url = val[`url`].split('/').reverse()[0];
        switch (url) {
          case 'heli-history':
            this.pageTitle = 'HELICOPTER';
            this.page_type = 'heli';
            break;
          case 'fixed-wing-history':
            this.pageTitle = 'FIXED WING';
            this.page_type = 'fixedwing';
            break;
        }
      }
    });
  }

  ngOnInit() {
    this.verifier.aircraftList({})
      .subscribe(res => {
        this.loader = false;
        this.aircraftList = res;
      });
  }
  onPageChange(number: number) {
    console.log('change to page', number);
    this.config.currentPage = number;
  }
  reset = () => {
    this.searchObj = {
      callsign: '',
      pilot: '',
      operator: '',
      start_date: moment().startOf('month').toDate(),
      end_date: moment().endOf('month').toDate()
    };
    this.reportList = [];
    this.search(this.searchObj);
  }
  post_to_url(path, params, method) {
    method = method || "post";

    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);
    form.setAttribute("target", "_blank");

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);

        form.appendChild(hiddenField);
      }
    }
    document.getElementById('download-form').appendChild(form);
    // document.body.appendChild(form);
    form.submit();
  }
  downloadHistory = () => {
    // this.search(this.searchObj);
    let searchObj = this.searchObj;
    var searchValue = {
      startDate: moment(searchObj.start_date).format('YYYY-MM-DD'),
      endDate: moment(searchObj.end_date).format('YYYY-MM-DD'),
      callsign: searchObj.callsign,
      // operator: searchObj.operator,
      page_type: this.page_type
    }
    this.post_to_url(apiUrl + 'fdtl/fdtlhistoryDownload', { query: JSON.stringify(searchValue) }, 'post');
  }
  search = (searchObj) => {
    this.loader = true;
    let self = this;
    setTimeout(function () {
      console.log('searchObj', searchObj.callsign)
      var searchValue = {
        startDate: moment(searchObj.start_date).format('YYYY-MM-DD'),
        endDate: moment(searchObj.end_date).format('YYYY-MM-DD'),
        callsign: searchObj.callsign,
        // operator: searchObj.operator,
        page_type: self.page_type
      }
      // if (key == 'callsign') {
      let userinfo = JSON.parse(localStorage.getItem('userinfo'));
      if (searchObj.callsign.length == 5 && userinfo.privilege_id != 1) {
        if (!userinfo.callsign.toLowerCase().includes(searchObj.callsign.toLowerCase()) && searchObj.callsign.toUpperCase() != 'VTXXX') {
          self.toastr.error('THIS CALL SIGN IS NOT LINKED TO YOUR ACCOUNT YET');
          return;
        }
      } else if (searchObj.callsign.length < 5 && userinfo.privilege_id != 1) {
        return;
      }
      // }
      if (searchObj.callsign.length >= 5) {
        self.aircraftList.map(val => {
          if (val.callsign == self.searchObj.callsign) {
            self.searchObj['operator'] = val.operator_name;
          }
        })
        self.verifier.searchHistoryReport(searchValue).subscribe(res => {
          console.log('result', res);

          // self.reportList = [];
          self.reportList = res;
          let tempReportList = self.reportList.filter((items, i) => {
            // console.log(items.action_type, items.flightDate, items.dateTime);
            if (items.action_type == 'ADD' || items.action_type == 'DELETE') {
              return true;
            } else {
              if (!self.reportList[i - 1]) {
                return true;
              }
              let condition = ((self.reportList[i - 1].chocks_off != items.chocks_off) || (self.reportList[i - 1].chocks_on != items.chocks_on) || (self.reportList[i - 1].pilot_name != items.pilot_name) || (self.reportList[i - 1].coPilot_name != items.coPilot_name) || (self.reportList[i - 1].RT != items.RT) || (self.reportList[i - 1].DET_15 != items.DET_15));
              // console.log(condition);
              // console.log(self.reportList[i - 1].chocks_off, items.chocks_off);
              // console.log(self.reportList[i - 1].chocks_o, items.chocks_o);
              // console.log(condition);
              return condition;
            }
          });
          self.reportList = tempReportList;
          self.reportList.map((e, i) => {
            e.index = i;
          })
          console.log('result', self.reportList);

          self.loader = false;
        });
      }
    }, 300)

  }

}
