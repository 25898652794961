import { environment } from "src/environments/environment";

var apiUrl = environment.apiUrl;

export const ApiUrlList = {
    "checkUser": apiUrl + "users/checkUser",
    "createJourney": apiUrl + "journey/create",
    "checkPlanExist": apiUrl + "journey/checkPlanExist",
    "fetchPreviousPlanInfo": apiUrl + "journey/fetchPreviousPlanInfo",
    "uniqueCallsign": apiUrl + "journey/uniqueCallsign",
    "listJourney": apiUrl + "journey/list",
    "journeygetByID": apiUrl + "journey/getByID",
    "addNewUser": apiUrl + "users/addNewUser",
    "updateUser": apiUrl + "users/updateUser",
    "addNewTempUser": apiUrl + "users/addNewTempUser",
    "updateTempUser": apiUrl + "users/updateTempUser",
    "getPilotList": apiUrl + "users/getAllPilots",
    "journeyUpdate": apiUrl + "journey/update?id=",
    "fuelUpdate": apiUrl + "fuelorder/cancelOrder",
    "getUserByCallsign": apiUrl + "users/getUserByCallsign",
    "checkuserByMobile": apiUrl + "users/checkuserByMobile",
    "checkTempuserByMobile": apiUrl + "users/checkTempuserByMobile",
    "fdtl_create": apiUrl + "fdtl/create",
    get_helischeduleReport: apiUrl + "fdtl/getHeliScheduleReport",
    getLastScheduleRecords: apiUrl + "fdtl/getLastScheduleRecords",
    addWeekOff: apiUrl + "fdtl/addWeekOff",
    updateWeekOff: apiUrl + "fdtl/updateWeekOff",
    getFDTLData: apiUrl + "fdtl/getFDTLData",
    getPilotReport: apiUrl + "fdtl/getPilotReport",
    search_report_fdtl: apiUrl + "fdtl/search_report_fdtl",
    search_report_fdtl_cabin_crew: apiUrl + "fdtl/search_report_fdtl_cabin_crew",
    listweekoff: apiUrl + "fdtl/list-weekoff",
    save_fdtl_fixedwing: apiUrl + "fdtl/save-fdtl-fixedwing",
    edit_fdtl_fixedwing: apiUrl + "fdtl/edit-fdtl-fixedwing",
    edit_fdtl_helicopter: apiUrl + "fdtl/fdtlheli-update",
    save_fdtl_helicopter: apiUrl + "fdtl/fdtlcalculator-live",
    helicopterTest: apiUrl + 'fdtl/fdtlcalculator',
    cabinTest: apiUrl + 'fdtl/fdtlcalculator-cabin-crew',
    fixed_wing_test: apiUrl + 'fdtl/fdtlcalculator-fixedwing',
    checkWeekOffValidation: apiUrl + 'fdtl/checkWeekOffValidation',
    addAirportTimings: apiUrl + 'fdtl/addAirportTimings',
    listAirportTimings: apiUrl + 'fdtl/listAirportTimings',
    contactForm: apiUrl + 'users/contact',
    forgotPassword: apiUrl + 'users/forgotpassword',
    add_operator: apiUrl + "users/add-operator",
    search_operator: apiUrl + "users/operator/search",
    updateOperator: apiUrl + "users/operator/update",
    checkOprCode: apiUrl + "users/operator/checkOprCode",
    getCabinCrew: apiUrl + "users/getCabinCrew",
    getCabinCrewReport: apiUrl + "fdtl/getCabinCrewReport",
    getAirCraftInfo: apiUrl + "users/operator/getAirCraftInfo",
    getCallSignLookupDetails : apiUrl + "users/callsignlookup",
    navlog: apiUrl + 'navlog/mainFunction',
    validate_hash: apiUrl + "users/validate-hash",
    reSetPassword: apiUrl + "users/reSetPassword",
    delete_Fdtl_Entry: apiUrl + "fdtl/delete-Fdtl-Entry",
    searchHistoryReport: apiUrl + "fdtl/search-history-report",
    aircraftList: apiUrl + "users/aircraftList",
    aeroDromlist: apiUrl + 'fdtl/aeroDromlist',
    findOperatorDetail: apiUrl + "users/findOperatorDetail",
    updateFplId: apiUrl + "navlogForm/updateFPLId",
    "create_licence_reminder": apiUrl + "license/add",
    "fetchAll_licence_reminder": apiUrl + "license/fetchAll",
    "update_licence_reminder": apiUrl + "license/update",
    "remove": apiUrl + "license/remove",
    licenseSearch: apiUrl + "license/licenseSearch",
    getNotams: apiUrl + 'notams/search',
    add_watch_hours: apiUrl + 'notams/add_watch_hours',
    list_watch_hours: apiUrl + 'notams/list_watch_hours',
    watch_hours_delete: apiUrl + 'notams/watch_hours_delete',
    watch_hours_by_id: apiUrl + 'notams/watch_hours_by_id',
    "download_pilot_license": apiUrl + "license/download?id=",
    download_operator_license: apiUrl + "license/download?operator="
};