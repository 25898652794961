import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/components/shared/login.service';
import * as moment from 'moment/moment';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/modules/fdtl/components/fdtl-history/fdtl-history.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ProfileComponent implements OnInit {
  operatorInfo:any;
  callsignlookupInfo:any;
  enableAddForm = false;
  enableNavlog = false;
  enableAddUser = false;
  userForm = {};
  searchForm = {};
  editmode = false;
  userList;
  editpilotForm;
  toastrInstance: any;
  loader = false;
  existUserList;
  privilageLookUp = [
    '',
    'SUPER ADMIN',
    'PILOT',
    'CO PILOT',
    'OPS STAFF',
    'CABIN CREW'
  ];
  editUserDetails = false;
  minDate = new Date(moment().format('YYYY/MM/DD'));
  userDetailId;
  showUserList;
  minEndDate;
  userinfo;
  constructor(private verifier: LoginService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router) {
    this.userinfo = JSON.parse(localStorage.getItem('userinfo'));
    this.operatorInfo = {
      det_time: '15'
    };
    this.route.queryParams.subscribe((params: Params) => {
      console.log('params', params)
      if (params && params.value) {
        this.userDetailId = params.value
        console.log('this.userDetailId', this.userDetailId)
        this.showUserList = true;
      }
      else if (params && params.enable && params.enable == 'true') {
        this.enableAddForm = true;
        this.enableAddUser = true;
        this.showUserList = false;
      }
    });

    if (this.userDetailId && this.userDetailId != undefined)
      this.getUserDetailsById(this.userDetailId);
  }
  ngOnInit() {

  }
  changeStartDate = (e) => {
    let startDate = moment(JSON.parse(JSON.stringify(e)));
    this.minEndDate = startDate.add(1, 'days');
    // console.log(startDate);
    // console.log(this.minEndDate);
  }
  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
  }
  allowDomesticCode(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)

    if (event.target.value.length == 0) {
      return (k == 86 || k == 118);
    } else if (event.target.value.length == 1) {
      return (k == 79 || k == 111 || k == 73 || k == 105 || k == 65 || k == 97 || k == 69 || k == 101);
    }
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
  }
  omit_special_char_allow_digits(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 47 && k <= 57));
  }
  allow_digits(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return (k >= 48 && k <= 57);
  }
  omit_special_char_allow_space(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
  }
  validateLicense(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    console.log(k);
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 45 && k <= 57));
  }
  getUserDetailsById = (idValue) => {
    this.verifier.findOperatorDetail({ id: idValue }).subscribe(res => {
      console.log('res', res);
      this.searchForm = res[0];
      this.operatorInfo = res[0];
      this.getCallSignLookupDetails();
      this.searchOperator();

    })

  }
  addNewOperator = () => {
    // this.enableAddForm = true;
    this.enableAddUser = true;
  }


  getCallSignLookupDetails = () => {
    this.callsignlookupInfo={};
    this.verifier.getCallSignLookupDetails({callsign:this.operatorInfo['callsign']}).subscribe( res => {
      console.log(res , "response");
    
      
      this.callsignlookupInfo = res[0];
      
    })
  }


  searchOperator = () => {
    this.verifier.searchOperator(this.searchForm)
      .subscribe(res => {
        console.log(res);
        if (res[0]) {
          // this.operatorInfo = res[0];
          this.verifier.getUserByCallsign({ callsign: this.operatorInfo['callsign'] })
            .subscribe(res => {
              console.log('user =======', res);
              this.userList = res;
              this.userList = this.userList.filter(val => {
                return val.is_permanent == '1';
              })
              // this.userList = res;
              if (this.userList.length > 0) {
                this.userList.map(item => {
                  item.editView = false;
                });
              }
            })
          if (this.userinfo.privilege_id == 1 && this.userinfo.role_type == 'admin') {
            this.enableAddForm = true;
            this.enableNavlog = true;
          }else if(this.userinfo.privilege_id == 1){
            if(this.userinfo.userId == 682 ||
              this.userinfo.userId == 684 ||
              this.userinfo.userId == 616 
              ){
                this.enableAddForm = true;
            }
            this.enableNavlog = true;
          }
          this.editmode = true;
        } else {
          this.operatorInfo = {
            det_time: '15'
          };
        }
      });
  }
  editOperatorDetails = () => {
    this.enableAddForm = true;
    this.editmode = true;
  }
  closeX = (e) => {
    e.editUserDetails = false;
  }
  clear = () => {
    this.userForm = {};
    this.enableAddUser = false;
  }
  saveData() {
    this.loader = true;
    if (this.editmode) {
      let _this = this;
      if (!this.operatorInfo['callsign']
        || !this.operatorInfo['address']
        || !this.operatorInfo['aircraft_type']
        || !this.operatorInfo['fdtl_count']
        || !this.operatorInfo['flight_type']
        || !this.operatorInfo['gst']
        || !this.operatorInfo['subscription_end_date']
        || !this.operatorInfo['subscription_start_date']
        || !this.operatorInfo['transport_time']
        || !this.operatorInfo['opr_code']
        || !this.operatorInfo['home_base']
      ) {
        if (!this.operatorInfo['is_charter']
          && !this.operatorInfo['is_fpl']
          && !this.operatorInfo['is_fuel']
          && !this.operatorInfo['is_handling']
          && !this.operatorInfo['is_fdtl']
          && !this.operatorInfo['is_lr']
          && !this.operatorInfo['is_lt']
          && !this.operatorInfo['is_navlog']
          && !this.operatorInfo['is_notams']
          && !this.operatorInfo['is_weather']
        ) {
          _this.toastrInstance = _this.toastr.error('Please choose any of one service', 'Alert');
          _this.loader = false;
          return;
        }
        _this.toastrInstance = _this.toastr.error('Please fill Mandatory fields', 'Alert');
        _this.loader = false;
        return;
      }
      if (this.operatorInfo['gst'].length < 15) {
        _this.toastrInstance = _this.toastr.error('Min and Max digits for GST is 15', 'Alert');
        _this.loader = false;
        return;
      }
      if (this.operatorInfo['opr_code'] && this.operatorInfo['opr_code'].length < 3) {
        _this.toastrInstance = _this.toastr.error('Min and Max characters for OPERATOR CODE is 3', 'Alert');
        _this.loader = false;
        return;
      }
      if (this.operatorInfo['home_base'] && this.operatorInfo['home_base'].length < 4) {
        _this.toastrInstance = _this.toastr.error('Min and Max characters for HOME BASE is 4', 'Alert');
        _this.loader = false;
        return;
      }
      this.operatorInfo['is_active'] = 1;

      this.verifier.checkOprCode(this.operatorInfo)
        .subscribe((opr: any) => {
          var opr_code_list = opr;
          if (opr_code_list.length == 1) {
            if (opr_code_list[0].callsign != this.operatorInfo['callsign']) {
              _this.toastrInstance = _this.toastr.error('OPERATOR CODE ALREADY EXISTS', 'Alert');
              _this.loader = false;
              return;
            }
          } else if (opr_code_list.length > 1) {
            _this.toastrInstance = _this.toastr.error('OPERATOR CODE ALREADY EXISTS', 'Alert');
            _this.loader = false;
            return;
          }
          this.verifier.updateOperator(this.operatorInfo)
            .subscribe(res => {
              this.operatorInfo = {
                det_time: '15'
              };
              this.userList = [];

              this.enableAddForm = false;
              let _this = this;
              setTimeout(function () {
                _this.loader = false;
                _this.toastrInstance = _this.toastr.success('OPERATOR DETAILS UPDATED SUCCESSFULLY', 'Alert');
                setTimeout(function () {
                  _this.cloaseToastr();
                  _this.router.navigate(['/aircraft-list']);
                }, 1000);
              }, 1000);
            });
        });

    } else {
      let _this = this;
      // console.log(this.operatorInfo);
      if (!this.operatorInfo['callsign']
        || !this.operatorInfo['address']
        || !this.operatorInfo['aircraft_type']
        || !this.operatorInfo['fdtl_count']
        || !this.operatorInfo['flight_type']
        || !this.operatorInfo['gst']
        || !this.operatorInfo['subscription_end_date']
        || !this.operatorInfo['subscription_start_date']
        || !this.operatorInfo['transport_time']
        || !this.operatorInfo['opr_code']
        || !this.operatorInfo['home_base']
      ) {
        if (!this.operatorInfo['is_charter']
          && !this.operatorInfo['is_fpl']
          && !this.operatorInfo['is_fuel']
          && !this.operatorInfo['is_handling']
          && !this.operatorInfo['is_fdtl']
          && !this.operatorInfo['is_lr']
          && !this.operatorInfo['is_lt']
          && !this.operatorInfo['is_navlog']
          && !this.operatorInfo['is_notams']
          && !this.operatorInfo['is_weather']
        ) {
          _this.toastrInstance = _this.toastr.error('Please choose any of one service', 'Alert');
          _this.loader = false;
          return;
        }
        _this.toastrInstance = _this.toastr.error('Please fill Mandatory fields', 'Alert');
        _this.loader = false;
        return;
      }
      if (this.operatorInfo['gst'].length < 15) {
        _this.toastrInstance = _this.toastr.error('Min and Max digits for GST is 15', 'Alert');
        _this.loader = false;
        return;
      }
      if (this.operatorInfo['opr_code'].length < 3) {
        _this.toastrInstance = _this.toastr.error('Min and Max characters for OPERATOR CODE is 3', 'Alert');
        _this.loader = false;
        return;
      }
      if (this.operatorInfo['home_base'].length < 4) {
        _this.toastrInstance = _this.toastr.error('Min and Max characters for HOME BASE is 4', 'Alert');
        _this.loader = false;
        return;
      }
      this.verifier.checkOprCode(this.operatorInfo)
        .subscribe((opr: any) => {
          var opr_code_list = opr;
          if (opr_code_list.length == 1) {
            if (opr_code_list[0].callsign != this.operatorInfo['callsign']) {
              _this.toastrInstance = _this.toastr.error('OPERATOR CODE ALREADY EXISTS', 'Alert');
              _this.loader = false;
              return;
            }
          } else if (opr_code_list.length > 1) {
            _this.toastrInstance = _this.toastr.error('OPERATOR CODE ALREADY EXISTS', 'Alert');
            _this.loader = false;
            return;
          }
          this.verifier.add_operator(this.operatorInfo)
            .subscribe(res => {
              this.operatorInfo = {
                det_time: '15'
              };
              this.userList = [];

              this.enableAddForm = false;
              let _this = this;
              setTimeout(function () {
                _this.loader = false;
                _this.toastrInstance = _this.toastr.success('Operator added successfully', 'Alert');
                setTimeout(function () {
                  _this.cloaseToastr();

                  _this.router.navigate(['/aircraft-list']);
                }, 1000);
              }, 1000);
            }, err => {
              console.log(err);
              let _this = this;
              _this.loader = false;
              _this.toastrInstance = _this.toastr.error(err.error.message, 'Alert');
            });
        });
    }

    console.log(this.operatorInfo);
  }
  cloaseToastr() {
    if (this.toastrInstance && this.toastrInstance.ToastId) {
      this.toastr.clear(this.toastrInstance.ToastId);
    }
    if (this.toastrInstance && this.toastrInstance.toastId) {
      this.toastr.clear(this.toastrInstance.toastId);
    }
    if (this.toastrInstance && this.toastrInstance.toastId) {
      this.toastr.clear(this.toastrInstance.toastId);
    }
  }
  save(type, index) {
    this.loader = true;
    this.cloaseToastr();
    if (type == 'add') {
      let crewCount = 0;
      if (this.operatorInfo['fdtl_count'] <= this.userList.length) {
        for (var i = 0; i < this.userList.length; i++) {
          if ((this.userList[i]['privilege_id'] == 2 || this.userList[i]['privilege_id'] == 3 || this.userList[i]['privilege_id'] == 5)) {
            crewCount++;
          }
        }
        // console.log(this.operatorInfo['fdtl_count'] , crewCount);
        if (this.operatorInfo['fdtl_count'] <= crewCount && (this.userForm['privilege_id'] == 2 || this.userForm['privilege_id'] == 3 || this.userForm['privilege_id'] == 5)) {
          this.toastrInstance = this.toastr.error('MAX CREW COUNT IS REACHED, CONTACT OUR ACCOUNTS TEAM TO INCREASE FDTL SUBSCRIPTION', 'Alert');
          this.loader = false;
          return;
        }
      }
      if (!this.userForm['privilege_id']
        || !this.userForm['mobile']
        || !this.userForm['email']
        || !this.userForm['username']) {
        this.toastrInstance = this.toastr.error('Please fill Mandatory fields', 'Alert');
        this.loader = false;
        return;
      }
      if ((this.userForm['privilege_id'] == 2 || this.userForm['privilege_id'] == 3) && !this.userForm['license_num']) {
        this.toastrInstance = this.toastr.error('License number is mandatory', 'Alert');
        this.userForm['licenseErr'] = true;
        this.loader = false;
        return;
      } else {
        this.userForm['licenseErr'] = false;
      }
      if (this.userForm['mobile'].length < 10) {
        this.toastrInstance = this.toastr.error('Min and Max digits for mobile number is 10', 'Alert');
        this.loader = false;
        return;
      }

      if (this.operatorInfo && this.userForm && this.userForm['privilege_id'] && this.operatorInfo['callsign']) {
        this.userForm['callsign'] = this.operatorInfo['callsign']
      }
      this.userForm['is_permanent'] = 1;
      this.verifier.checkuserByMobile({ mobile: this.userForm['mobile'], email: this.userForm['email'], callsign: this.userForm['callsign'] })
        .subscribe(user => {
          console.log(user);
          this.existUserList = user;
          if (this.existUserList.length > 0) {
            this.loader = false;
            this.toastrInstance = this.toastr.error('USER WITH SAME MOBILE / EMAIL ALREADY EXIST', 'Alert');
          } else {
            this.verifier.addNewUser(this.userForm)
              .subscribe(res => {
                this.enableAddUser = false;
                this.userList.push(this.userForm);
                this.userForm = {};
                console.log('res', res);
                let _this = this;
                setTimeout(function () {
                  _this.loader = false;
                  _this.toastrInstance = _this.toastr.success('User added successfully', 'Alert');
                  _this.userList[_this.userList.length - 1].editUserDetails = false;
                  setTimeout(function () {
                    _this.cloaseToastr();
                  }, 2000);
                }, 1000);
              }, err => {
                let _this = this;
                _this.toastrInstance = _this.toastr.error(err.message, 'Alert');
                setTimeout(function () {
                  _this.cloaseToastr();
                }, 1000);
                console.log('err', err);
              })
          }
        })

    } else {
      // if (this.operatorInfo['fdtl_count'] <= this.userList.length) {
      //   this.toastrInstance = this.toastr.error('MAX CREW COUNT IS REACHED, CONTACT OUR ACCOUNTS TEAM TO INCREASE FDTL SUBSCRIPTION', 'Alert');
      //   this.loader = false;
      //   return;
      // }
      if (!this.userList[index]['privilege_id']
        || !this.userList[index]['mobile']
        || !this.userList[index]['email']
        || !this.userList[index]['username']) {
        this.toastrInstance = this.toastr.error('Please fill Mandatory fields', 'Alert');
        this.loader = false;
        return;
      }
      if ((this.userList[index]['privilege_id'] == 2 || this.userList[index]['privilege_id'] == 3) && !this.userList[index]['license_num']) {
        this.toastrInstance = this.toastr.error('License number is mandatory', 'Alert');
        this.userList[index]['licenseErr'] = true;
        this.loader = false;
        return;
      } else {
        this.userList[index]['licenseErr'] = false;
      }
      if (this.userList[index]['mobile'].length < 10) {
        this.toastrInstance = this.toastr.error('Min and Max digits for mobile number is 10', 'Alert');
        this.loader = false;
        return;
      }

      // if (this.operatorInfo && this.userList[index] && this.userList[index]['privilege_id'] && this.operatorInfo['callsign']) {
      //   this.userList[index]['callsign'] = this.operatorInfo['callsign']
      // }
      this.verifier.checkuserByMobile({ mobile: this.userList[index]['mobile'], email: this.userList[index]['email'], callsign: this.userList[index]['callsign'] })
        .subscribe(user => {
          console.log(user);
          let _this = this;

          this.existUserList = user;
          if (this.existUserList.length > 1) {
            this.loader = false;
            this.toastrInstance = this.toastr.error('USER WITH SAME MOBILE / EMAIL ALREADY EXIST', 'Alert');
          } else {
            if (this.existUserList.length == 1) {
              if (this.existUserList[0].userId != this.userList[index]['userId']) {
                _this.toastrInstance = _this.toastr.error('USER WITH SAME MOBILE / EMAIL ALREADY EXIST', 'Alert');
                return;
              }
            }
            this.verifier.updateUser(this.userList[index]).subscribe(res => {
              this.enableAddUser = false;
              setTimeout(function () {
                _this.loader = false;
                _this.toastrInstance = _this.toastr.success('User updated successfully', 'Alert');
                _this.userList[index].editUserDetails = false;
                setTimeout(function () {
                  _this.cloaseToastr();
                }, 1000);
              }, 1000);
            }, err => {
              console.log('err', err)
            })
          }
        });

    }

  }
  onFileChange(event, type, index) {
    if (event && event.target && event.target.files && event.target.files[0] && event.target.files[0].size) {
      var imgeSize = event.target.files[0].size >= 1024 ? (event.target.files[0].size / 1024).toFixed(1) : event.target.files[0].size;
    }
    let reader = new FileReader();
    console.log(event);
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (imgeSize && imgeSize < 100) {
          if (type == 'edit') {
            this.userList[index].signature = reader.result;
            console.log(index, this.userList[index]);
            // this.editpilotForm.signature = reader.result;
          } else {
            this.userForm['signature'] = reader.result;
          }
        } else {
          this.userForm['signature'] = '';
          var errorMessage = 'image size should be less than 100kb'
          this.toastr.error(errorMessage.toUpperCase(), 'Alert');
        }
      };
    }
  }
  editRoleDetails(e) {
    if (this.enableAddForm) {
      e.editUserDetails = true;
    }
  }
  cancelEdit() {
    this.router.navigate(['/aircraft-list']);
  }
  deleteUserDetails(index) {
    if (!this.enableAddForm) {
      return;
    }
    let mes = 'Are you sure to delete this user';
    Swal.fire({
      title: 'DELETE USER?',
      text: mes.toUpperCase(),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        if (this.userList && this.userList[index]) {
          this.userList[index].active = 0;
        }

        let callsignArr = this.userList[index].callsign.split(',');

        var data = {}
        if (callsignArr.length == 1) {
          data = {
            userId: this.userList[index].userId,
            active: this.userList[index].active
          }
        } else {
          for (let i = 0; i < callsignArr.length; i++) {
            if (this.operatorInfo['callsign'].toLowerCase() == callsignArr[i].toLowerCase()) {
              callsignArr.splice(0, 1);
            }
          }
          data = {
            userId: this.userList[index].userId,
            callsign: callsignArr.join(',')
          }
        }
        // console.log(data);
        // return;
        this.verifier.updateUser(data).subscribe(res => {
          this.userList.splice(index, 1);
          this.enableAddUser = false;
        }, err => {
          console.log('err', err)
        })

      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })
  }

}
