import { Component, OnInit } from '@angular/core';
import * as moment from 'moment/moment';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  utc_time;
  utc_date;
  utc_ddd;
  ddFlag = false;
  locationPath;
  userinfo;
  constructor(public router: Router, location: Location, private toastr: ToastrService) {

    this.utc_time = moment().utc().format('HH:mm');
    this.utc_date = moment().utc().format('DD-MMM-YYYY');
    this.utc_ddd = moment().utc().format('dddd');
    var self = this;
    setInterval(function () {
      self.userinfo = JSON.parse(localStorage.getItem('userinfo'));
      self.utc_time = moment().utc().format('HH:mm');
      self.utc_date = moment().utc().format('DD-MMM-YYYY');
      self.utc_ddd = moment().utc().format('dddd');
    }, 1000);
  }

  ngOnInit() {
    
  }
  logout() {
    Swal.fire({
      title: 'ALERT',
      text: 'ARE YOU SURE TO LOG OUT?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'YES',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.value) {
        localStorage.clear();
        this.router.navigate(['/']);
      }
    })

  }
  profile() {

    if (this.userinfo.privilege_id == 6) {
      this.toastr.error('YOUR ACCOUNT IS NOT YET SUBSCRIBED TO THIS PAGE, CONTACT OUR ACCOUNTS TEAM TO ACTIVATE');
      return;
    }
    this.router.navigate(['/aircraft-list']);
  }
  toggleDropDown(flag) {
    // console.log(flag);
    this.ddFlag = flag;
  }

}
