import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'app-loader',
    templateUrl: './loader.html',
})
export class Loader implements OnInit {
    ngOnInit() {
    }
}
