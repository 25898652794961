import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {
  
  constructor(private http:HttpClient) {
   }
  
   post(url,reqBody,options?){
    const head =new Headers().set('content-Type','application/json');
    return this.http.post(url,reqBody,options);
   }

   get(url,params?){
    const head =new Headers().set('content-Type','application/json');
    return this.http.get(url+params);
   }

}
